<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn text x-small v-on="on" @click="showDialog">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </span>
        </template>
        <span> Delete request </span>
      </v-tooltip>
    </template>

    <v-card class="primary--text">
      <v-card-title>
        Are you sure you want to delete this request?
      </v-card-title>

      <v-card-text> Note: This action cannot be undone. </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark text @click="cancel">Cancel</v-btn>
        <v-btn color="primary" dark outlined @click="deleteRequest"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["requestId"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    deleteRequest: async function () {
      await this.$store.dispatch("paidSickLeaveModule/deleteRequest", {
        requestId: this.requestId,
      });
      this.dialog = false;
    },
  },
};
</script>
