<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn text x-small v-on="on" @click="goToViewRequest">
          <v-icon small>mdi-magnify</v-icon>
        </v-btn>
      </span>
    </template>
    <span> View request details</span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["request"],
  methods: {
    goToViewRequest() {
      this.$router.push({
        name: "ViewRequest",
        params: {
          request: this.request,
          noEdit: true,
        },
      });
    },
  },
};
</script>
