<template>
  <v-btn
    small
    color="primary"
    :dark="selectedFiles.length > 0"
    :disabled="selectedFiles.length < 1"
    @click="download"
  >
    <v-icon left>mdi-cloud-download</v-icon>
    Download Zip
  </v-btn>
</template>

<script>
import { downloadZip } from "../../utils/download-utils";
export default {
  props: ["selectedFiles"],
  data() {
    return {
      zipFileName: "payroll_remittances.zip",
    };
  },
  methods: {
    download: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      try {
        await downloadZip(this.selectedFiles, this.$store, this.zipFileName);
      } catch (err) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>