import * as api from "../Services/api.js";
import PayrollRemittance from "../../objs/PayrollRemittance";
export const state = {
  payrollRemittances: [],
};
export const mutations = {
  SET_REMITTANCES(state, payload) {
    state.payrollRemittances = payload.map(
      (item) => new PayrollRemittance(item)
    );
  },
};

export const getters = {
  displayRemittances: (state) => {
    return state.payrollRemittances
      .filter((item) => item.fileId)
      .sort((a, b) => {
        return new Date(b.paycheckDate) - new Date(a.paycheckDate);
      });
  },
};

export const actions = {
  async getRemittances({ commit, rootState, dispatch }) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }

    return await api
      .getQuery("/paystub/getProviderRemittances", { providerId: providerId })
      .then((response) => {
        if (response.success) {
          commit("SET_REMITTANCES", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
