import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import { FontAwesomeIcon } from "./icons";
import VueTheMask from "vue-the-mask";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import FormField from "./components/FormFields/form-field.vue";
import vuetify from "./plugins/vuetify";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "font-awesome/css/font-awesome.css";
require("./css/site.css");

Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: "mdi" });
Vue.use(VueTheMask);

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);
setInteractionMode("lazy");

Vue.component("icon", FontAwesomeIcon);
Vue.component("form-field", FormField);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
