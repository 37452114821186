import * as api from "../Services/api.js";
import PaidSickLeaveRequestSession from "../../objs/PaidSickLeaveRequestSession";
import PaidSickLeaveRequest from "../../objs/PaidSickLeaveRequest";

export const state = {
  totalHoursAvailable: 0,
  hoursPendingRequests: 0,
  hoursAppliedThisYear: 0,
  remainingHoursAvailable: 0,
  schoolYear: 0,
  maxHoursAllowedPerSchoolYear: 0,
  paidSickLeaveRequests: [],
  cancelledSessions: [],
};
export const mutations = {
  SET_MAX_HOURS_ALLOWED(state, payload) {
    state.maxHoursAllowedPerSchoolYear = payload;
  },
  SET_BALANCE_DETAILS(state, payload) {
    state.totalHoursAvailable =
      Math.round(payload.totalHoursAvailable * 10) / 10;
    state.hoursPendingRequests =
      Math.round(payload.hoursPendingRequests * 10) / 10;
    state.hoursAppliedThisYear =
      Math.round(payload.hoursAppliedThisYear * 10) / 10;
    state.remainingHoursAvailable =
      Math.round(payload.remainingHoursAvailable * 10) / 10;
    state.schoolYear = payload.schoolYear;
  },
  SET_CANCELLED_SESSIONS(state, payload) {
    state.cancelledSessions = payload.map(
      (item) => new PaidSickLeaveRequestSession(item)
    );
  },
  SET_REQUESTS(state, payload) {
    state.paidSickLeaveRequests = payload.map(
      (item) => new PaidSickLeaveRequest(item)
    );
  },
  ADD_REQUEST(state, payload) {
    state.paidSickLeaveRequests.push(new PaidSickLeaveRequest(payload));
  },
  UPDATE_REQUEST(state, payload) {
    state.paidSickLeaveRequests = state.paidSickLeaveRequests.map((item) => {
      return item.paidSickLeaveRequestId == payload.paidSickLeaveRequestId
        ? new PaidSickLeaveRequest(payload)
        : item;
    });
  },
  DELETE_REQUEST(state, requestId) {
    state.paidSickLeaveRequests = state.paidSickLeaveRequests.filter(
      (item) => item.paidSickLeaveRequestId != requestId
    );
  },
};

export const getters = {};

export const actions = {
  async getMaxPaidSickLeaveHoursPerSchoolYear({ commit }) {
    return await api
      .get("/paidSickLeave/getMaxPaidSickLeaveHoursPerSchoolYear")
      .then((response) => {
        if (response.success) {
          commit("SET_MAX_HOURS_ALLOWED", response.data);
        }
        return response.success;
      });
  },
  async getBalanceDetails({ commit, rootState, dispatch }, data) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }

    return await api
      .getQuery("/paidSickLeave/getBalanceDetailsForProvider", {
        providerId: providerId,
        date: data.date,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_BALANCE_DETAILS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getAllRequests({ commit, rootState, dispatch }) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }

    return await api
      .getQuery("/paidSickLeave/loadPaidSickLeaveRequestsForProvider", {
        providerId: providerId,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_REQUESTS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getCancelledSessionsForDateRange(
    { commit, rootState, dispatch },
    data
  ) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }

    return await api
      .getQuery("/paidSickLeave/getCancelledSessionsForProviderDateRange", {
        providerId: providerId,
        start: data.startDate,
        end: data.endDate,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_CANCELLED_SESSIONS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async submitNewRequest({ commit, rootState, dispatch }, data) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }

    return await api
      .post("/paidSickLeave/createPaidSickLeaveRequest", {
        ...data,
        providerId: providerId,
        requestSubmittedAt: new Date(Date.now()),
      })
      .then((response) => {
        if (response.success) {
          commit("ADD_REQUEST", response.data);
          dispatch("getBalanceDetails", {
            date: new Date(Date.now()),
          });
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return "An error occurred while creating this request";
      });
  },
  async updateRequest({ commit, rootState, dispatch }, data) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }

    return await api
      .post("/paidSickLeave/updatePaidSickLeaveRequest", {
        ...data,
        providerId: providerId,
        requestSubmittedAt: new Date(Date.now()),
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_REQUEST", response.data);
          dispatch("getBalanceDetails", {
            date: new Date(Date.now()),
          });
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return "An error occurred while updating this request";
      });
  },
  async deleteRequest({ commit, dispatch }, data) {
    return await api
      .postQuery("/paidSickLeave/deletePaidSickLeaveRequest", {
        paidSickLeaveRequestId: data.requestId,
      })
      .then((response) => {
        if (response.success) {
          commit("DELETE_REQUEST", response.data);
          dispatch("getBalanceDetails", {
            date: new Date(Date.now()),
          });
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
