<template>
  <div>
    <v-subheader>
      <h3>Sorry, you do not have permission to access this page.</h3>
    </v-subheader>
    <v-subheader>
      Details: The {{ pageName }} requires user role: {{ roles }}. For help,
      please contact support@omniuserportal.com.
    </v-subheader>
    <router-link :to="{ name: 'Home' }" class="ma-4">Go Back</router-link>
  </div>
</template>
<script>
import { formatListOr } from "../../utils/format-utils";
export default {
  computed: {
    pageName: function () {
      return this.$route.query.page.concat(" page");
    },
    roles: function () {
      return formatListOr(this.$route.query.roles);
    },
  },
};
</script>