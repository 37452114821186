<template>
  <v-card-subtitle v-if="noRemittances"
    >You have no remittances available to display.</v-card-subtitle
  >
  <v-data-table
    v-else
    v-model="selectedItems"
    :headers="headers"
    :items="remittances"
    item-key="remittanceReportId"
    :loading="loading"
    loading-text="Loading... Please wait"
    show-select
  >
    <template v-slot:[`item.actions`]="{ item }">
      <preview-file-action :fileId="item.fileId"></preview-file-action>
      <download-file-action
        :fileId="item.fileId"
        :filename="item.filename"
      ></download-file-action>
    </template>
  </v-data-table>
</template>
<script>
import DownloadFileAction from "../Shared/download-file-action.vue";
import PreviewFileAction from "../Shared/preview-file-action.vue";
export default {
  components: {
    PreviewFileAction,
    DownloadFileAction,
  },
  mounted: async function () {
    await this.$store.dispatch("payrollModule/getRemittances");
    this.loading = false;
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          align: "start",
          sortable: false,
          width: 3,
          value: "type",
        },
        { text: "Report ID", value: "remittanceReportId" },
        { text: "Paycheck Date", value: "formattedPaycheckDate" },
        { text: "Payroll Period Ending", value: "formattedEndDate" },
        { text: "Total Payout", value: "formattedTotalPayout" },
        { text: "Remittance", value: "actions", sortable: false },
      ],
      selectedItems: []
    };
  },
  computed: {
    remittances: function () {
      return this.$store.getters["payrollModule/displayRemittances"];
    },
    noRemittances: function () {
      return !this.loading && this.remittances.length == 0;
    },
  },
  watch: {
    selectedItems: function () {
      this.$emit("toggleSelection", this.selectedItems);
    },
  },
};
</script>
<style>
</style>