<template>
  <v-card width="200" height="auto" class="primary--text">
    <v-card-title>My Balance</v-card-title>
    <v-card-subtitle>as of {{ formattedDateNow }}</v-card-subtitle>
    <v-card-text>
      <div class="text-h4 text-center">{{ totalHoursAvailable }}</div>
      <div class="text-overline text-center">
        Available Hours<span v-if="showMaxHoursMessage">*</span>
      </div>
      <v-divider class="my-4"></v-divider>
      <div class="text-h4 text-center">{{ hoursPendingRequests }}</div>
      <div class="text-overline text-center">Pending Requests Hours</div>
      <v-divider class="my-4"></v-divider>
      <div class="text-h4 text-center">{{ hoursAppliedThisYear }}</div>
      <div class="text-overline text-center">
        Hours Applied SY{{ schoolYear }}
      </div>
    </v-card-text>
    <v-card-subtitle v-show="showMaxHoursMessage" class="text-caption"
      >* max allowed to request is {{ maxHoursAllowedPerSchoolYear }} hours per
      school year
    </v-card-subtitle>
  </v-card>
</template>
<script>
import { formatDate } from "../../utils/format-utils";
export default {
  computed: {
    formattedDateNow() {
      return formatDate(Date.now());
    },
    totalHoursAvailable() {
      return this.$store.state.paidSickLeaveModule.totalHoursAvailable || 0;
    },
    hoursPendingRequests() {
      return this.$store.state.paidSickLeaveModule.hoursPendingRequests || 0;
    },
    hoursAppliedThisYear() {
      return this.$store.state.paidSickLeaveModule.hoursAppliedThisYear || 0;
    },
    schoolYear() {
      return this.$store.state.paidSickLeaveModule.schoolYear;
    },
    maxHoursAllowedPerSchoolYear() {
      return this.$store.state.paidSickLeaveModule.maxHoursAllowedPerSchoolYear;
    },
    showMaxHoursMessage() {
      return (
        this.totalHoursAvailable + this.hoursAppliedThisYear >=
        this.maxHoursAllowedPerSchoolYear
      );
    },
  },
};
</script>
