<template>
  <session-table :sessions="sessions" :loading="loading"> </session-table>
</template>

<script>
import SessionTable from "./session-table.vue";
export default {
  components: { SessionTable },
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    this.isAdmin
      ? await this.$store.dispatch(
          "evalInvoiceModule/getAllEvalInvoiceSessions"
        )
      : await this.$store.dispatch(
          "evalInvoiceModule/getEvalInvoiceSessionsForProvider"
        );
    this.loading = false;
  },
  computed: {
    sessions() {
      return this.$store.state.evalInvoiceModule.sessions;
    },
    isAdmin() {
      return this.$store.getters["securityModule/hasUserRole"](["PayrollAdmin"]);
    },
  },
};
</script>
