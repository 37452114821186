<template>
  <v-dialog v-model="dialog" persistent width="80vw" height="80vw">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        :dark="!disabled"
        rounded
        small
        :disabled="disabled"
        @click="lookupSessions"
        v-bind="attrs"
        v-on="on"
      >
        Lookup sessions to apply
      </v-btn>
    </template>
    <v-card class="mx-auto">
      <v-card-title
        >Select the cancelled sessions to apply paid sick leave
        hours</v-card-title
      >
      <v-card-text class="my-4">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="cancelledSessions"
          item-key="sessionId"
          :loading="loading"
          show-select
          @item-selected="onSelect"
        >
          <template v-slot:[`header.data-table-select`]></template>
          <template
            v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
          >
            <span v-if="item.isPartialSession" class="error--text">*</span>
            <v-simple-checkbox
              :value="isSelected"
              @input="select($event)"
              :disabled="(maxHours && !isSelected) || noEdit"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-subheader v-show="showPartialSessionMessage" class="error--text"
              >* partial session will be applied
            </v-subheader>
          </template>
        </v-data-table>
        <v-row>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-subheader v-show="maxHours" class="error--text"
          >You've reached your maximum available hours to request paid sick
          leave.
        </v-subheader>
        <v-col cols="2">
          <v-text-field
            label="Total hours"
            :value="hoursRequested"
            type="numeric"
            readonly
            outlined
            hide-details
          >
          </v-text-field>
        </v-col>
        <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="primary" text @click="apply" :disabled="noEdit">
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["startDate", "endDate", "disabled", "request", "noEdit"],
  data() {
    return {
      dialog: false,
      loading: false,
      hoursRequested: 0,
      headers: [
        { text: "Session ID", value: "sessionId", align: "left" },
        { text: "Activity ID", value: "legacyActivityId" },
        { text: "Client", value: "clientName" },
        { text: "Date of Service", value: "formattedDateOfService" },
        { text: "Start Time", value: "formattedStartTime" },
        { text: "Duration (in minutes)", value: "durationMinutes" },
        { text: "Program", value: "programName" },
        { text: "Service", value: "serviceName" },
        { text: "Location", value: "location" },
        { text: "Group Size", value: "groupSize" },
        { text: "Language", value: "language" },
      ],
      selected: [],
      maxHours: false,
    };
  },
  computed: {
    cancelledSessions() {
      return this.$store.state.paidSickLeaveModule.cancelledSessions || [];
    },
    hoursAvailableToRequest() {
      let hoursAvailableToRequest =
        this.$store.state.paidSickLeaveModule.remainingHoursAvailable -
        this.$store.state.paidSickLeaveModule.hoursPendingRequests;
      if (this.request) {
        return hoursAvailableToRequest + this.request.hoursRequested;
      }
      return hoursAvailableToRequest;
    },
    showPartialSessionMessage() {
      return this.selected.some((item) => item.isPartialSession);
    },
  },
  watch: {
    selected() {
      let hours = 0;
      this.selected.forEach((session) => {
        hours += session.minutesCovered / 60;
      });
      if (hours >= this.hoursAvailableToRequest) {
        this.hoursRequested = this.hoursAvailableToRequest;
        this.maxHours = true;
      } else {
        this.hoursRequested = hours;
        this.maxHours = false;
      }
    },
  },
  methods: {
    async lookupSessions() {
      this.dialog = true;
      this.loading = true;
      await this.$store.dispatch(
        "paidSickLeaveModule/getCancelledSessionsForDateRange",
        {
          startDate: new Date(this.startDate),
          endDate: new Date(this.endDate),
        }
      );
      this.selected = this.request?.sessions || [];
      this.hoursRequested = this.request?.hoursRequested || 0;
      this.maxHours = this.hoursAvailableToRequest === 0;
      this.loading = false;
    },
    onSelect({ item, value }) {
      if (value) {
        // checked
        let totalHours =
          this.hoursRequested +
          Math.round((item.durationMinutes / 60) * 10) / 10;
        if (totalHours > this.hoursAvailableToRequest) {
          // partial session
          item.minutesCovered = Math.round(
            (this.hoursAvailableToRequest - this.hoursRequested) * 60
          );
        } else {
          item.minutesCovered = item.durationMinutes;
        }
      } else {
        // unchecked
        item.minutesCovered = 0;
      }
    },
    apply() {
      this.$emit("setHoursRequested", this.hoursRequested);
      this.$emit("setSessions", this.selected);
      this.dialog = false;
    },
  },
};
</script>