import * as api from "../Services/api.js";
import EvalInvoiceSessionDetails from "../../objs/EvalInvoiceSessionDetails";
export const state = {
  sessions: [],
  filters: {
    clientFilter: "",
    providerFilter: "",
    dateOfServiceFilter: null,
    missingInvoicesToggle: false,
  },
};
export const mutations = {
  SET_SESSIONS(state, payload) {
    state.sessions = payload.map((item) => new EvalInvoiceSessionDetails(item));
  },
  UPDATE_SESSIONS(state, payload) {
    // maintain rate b/c can be overwritten on backend
    for (var i = 0; i < payload.length; i++) {
      const findIndex = state.sessions.findIndex(
        ({ sessionId }) => sessionId === payload[i].sessionId
      );
      var amount = state.sessions[findIndex].dollarAmount;
      state.sessions.splice(
        findIndex,
        1,
        new EvalInvoiceSessionDetails(payload[i])
      );
      state.sessions[findIndex].dollarAmount = amount;
    }
  },

  SET_FILTERS(state, payload) {
    state.filters[payload.filterName] = payload.filterValue;
  },
  CLEAR_FILTERS(state) {
    state.filters = {
      clientFilter: "",
      providerFilter: "",
      dateOfServiceFilter: null,
      missingInvoicesToggle: false,
    };
  },
};

export const actions = {
  async getAllEvalInvoiceSessions({ commit }) {
    return await api
      .getQuery("/sessionDetails/LoadAllSessionsRequireEvalInvoice")
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getEvalInvoiceSessionsForProvider({ commit, rootState, dispatch }) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }
    return await api
      .getQuery("/sessionDetails/LoadSessionsRequireEvalInvoiceForProvider", {
        providerId: providerId,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async generateEvalInvoices({ commit }, sessions) {
    return await api
      .post("/sessionDetails/generateEvalInvoices", {
        sessions: sessions,
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_SESSIONS", response.data);
          return response.message;
        }
        return false;
      })
      .catch((error) => {
        return false;
      });
  },
  async sendMissingRateNotification({ commit }, sessionId) {
    return await api
      .postQuery("/sessionDetails/SendMissingRateNotification", {
        sessionId: sessionId,
      })
      .then((response) => {
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  setFilters({ commit }, data) {
    commit("SET_FILTERS", data);
  },
  clearFilters({ commit }) {
    commit("CLEAR_FILTERS");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
