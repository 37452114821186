<template>
  <v-row>
    <v-col cols="2">
      <balances></balances>
    </v-col>
    <v-col cols="10">
      <router-view></router-view>
    </v-col>
  </v-row>
</template>
<script>
import Balances from "./balances.vue";
export default {
  components: {
    Balances,
  },
  async mounted() {
    await this.$store.dispatch(
      "paidSickLeaveModule/getMaxPaidSickLeaveHoursPerSchoolYear"
    );
    await this.$store.dispatch("paidSickLeaveModule/getBalanceDetails", {
      date: new Date(Date.now()),
    });
    await this.$store.dispatch("paidSickLeaveModule/getAllRequests");
  },
};
</script>