import Home from "../components/HomePage/home";
import PayrollRemittances from "../components/PayrollRemittances/payroll-remittances";
import PaidSickLeave from "../components/PaidSickLeave/paid-sick-leave-home";
import PaidSickLeaveRequests from "../components/PaidSickLeave/paid-sick-leave-requests";
import RequestForm from "../components/PaidSickLeave/request-form";
import EvalInvoices from "../components/EvalInvoices/eval-invoices";
import Error from "../components/Security/error";
import Callback from "../components/Security/callback";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: "home",
    title: "Home",
    component: Home,
    onNavBar: true,
    onHomePage: false,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "PayrollRemittances",
    path: "/remittances",
    icon: "file-invoice-dollar",
    title: "My Payroll Remittances",
    component: PayrollRemittances,
    onNavBar: true,
    onHomePage: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paid-sick-leave",
    icon: "briefcase-medical",
    title: "Paid Sick Leave",
    component: PaidSickLeave,
    onNavBar: true,
    onHomePage: true,
    meta: {
      requiresAuth: true,
      seit: true,
    },
    children: [
      {
        name: "PaidSickLeave",
        path: "",
        icon: "briefcase-medical",
        title: "All Requests",
        component: PaidSickLeaveRequests,
        meta: {
          requiresAuth: true,
          seit: true,
        },
      },
      {
        name: "NewRequest",
        path: "/paid-sick-leave/new-request",
        icon: "sticky-note",
        title: "New Request",
        component: RequestForm,
        onNavBar: true,
        props: true,
        meta: {
          requiresAuth: true,
          seit: true,
        },
      },
      {
        name: "EditRequest",
        path: "/paid-sick-leave/edit-request",
        icon: "sticky-note",
        title: "Edit Request",
        component: RequestForm,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
          seit: true,
        },
      },
      {
        name: "ViewRequest",
        path: "/paid-sick-leave/view-request",
        icon: "sticky-note",
        title: "View Request",
        component: RequestForm,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
          seit: true,
        },
      },
    ],
  },
  {
    name: "EvalInvoice",
    path: "/eval-invoices",
    icon: "file-invoice",
    title: "Evaluation Invoices",
    component: EvalInvoices,
    onNavBar: true,
    onHomePage: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Error",
    path: "/error",
    title: "Error",
    component: Error,
    onNavBar: false,
    onHomePage: false,
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
    onHomePage: false,
  },
];
