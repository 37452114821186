import { formatDate } from "../utils/format-utils";
export default class EvalInvoiceSessionDetails {
  sessionId?: number | null;
  clientName?: string | null;
  providerName?: string | null;
  dateOfService?: Date | null;
  programId?: number | null;
  programName?: string | null;
  serviceName?: string | null;
  location?: string | null;
  groupSize?: number | null;
  language?: string | null;
  dollarAmount?: number | null;
  evalInvoiceReceived?: boolean | null;
  evalInvoiceFileId?: number | null;
  evalInvoiceNotificationSent?: boolean | null;

  public constructor(params: EvalInvoiceSessionDetails = {} as EvalInvoiceSessionDetails) {
    let {
      sessionId = 0,
      clientName = "",
      providerName = "",
      dateOfService = null,
      programId = 0,
      programName = "",
      serviceName = "",
      groupSize = 0,
      language = "",
      dollarAmount = null,
      location = "",
      evalInvoiceReceived = false,
      evalInvoiceFileId = null,
      evalInvoiceNotificationSent = false,
    } = params;

    this.sessionId = sessionId;
    this.clientName = clientName;
    this.providerName = providerName;
    this.dateOfService = dateOfService;
    this.programId = programId;
    this.programName = programName;
    this.serviceName = serviceName;
    this.groupSize = groupSize;
    this.language = language;
    this.dollarAmount = dollarAmount;
    this.location = location;
    this.evalInvoiceReceived = evalInvoiceReceived;
    this.evalInvoiceFileId = evalInvoiceFileId;
    this.evalInvoiceNotificationSent = evalInvoiceNotificationSent;
  }

  get formattedDateOfService() {
    return formatDate(this.dateOfService);
  }
  get filename() {
    return `EvalInvoice_${this.sessionId}.pdf`;
  }
}
