<template>
  <v-card class="mx-auto">
    <v-card-title>
        <v-toolbar-title>My Payroll Remittances</v-toolbar-title>
        <v-spacer></v-spacer>
        <download-zip-button :selectedFiles="selectedItems"></download-zip-button>
    </v-card-title>
    <payroll-remittances-table @toggleSelection="toggleSelection"></payroll-remittances-table>
  </v-card>
</template>
<script>
import DownloadZipButton from "./download-zip-button";
import PayrollRemittancesTable from "./payroll-remittances-table";
export default {
  components: {
    DownloadZipButton,
    PayrollRemittancesTable,
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  methods: {
    toggleSelection: function (selected) {
      this.selectedItems = selected;
    },
  },
};
</script>
