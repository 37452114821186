<template>
  <v-menu
    v-model="showDatePicker"
    :close-on-content-click="false"
    max-width="290"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <validation-provider :rules="validations" v-slot="{ errors }">
        <v-text-field
          :value="formattedDateValue"
          :label="field.label"
          clearable
          @click:clear="clearDate"
          prepend-inner-icon="mdi-calendar"
          style="width: 370px"
          :error-messages="errors"
          v-on="on"
          color="#005c7b"
        ></v-text-field>
      </validation-provider>
    </template>
    <v-date-picker
      :value="dateFormattedForPicker"
      @input="onDateChange"
      :disabled="readonly"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ["field", "value", "validations", "readonly"],
  data() {
    return {
      showDatePicker: false,
    };
  },
  computed: {
    selectedDate: {
      get: function () {
        return this.value instanceof Date ? this.value : "";
      },
      set: function (newValue) {},
    },
    formattedDateValue: function () {
      if (!this.dateValueEmpty) {
        let options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return this.selectedDate.toLocaleString("en-US", options);
      }
      return "";
    },
    dateValueEmpty: function () {
      return this.selectedDate === "";
    },
    dateFormattedForPicker: function () {
      if (!this.dateValueEmpty) {
        return this.selectedDate.toISOString().substr(0, 10);
      }
      return "";
    },
  },
  methods: {
    onDateChange: function (event) {
      this.showDatePicker = false;
      const [year, month, day] = event.split("-");
      let date = new Date(year, month - 1, day, 0, 0, 0, 0);
      this.selectedDate = date;
      this.$emit("fieldChange", date);
    },
    clearDate: function () {
      this.selectedDate = "";
      this.$emit("fieldChange", "");
    },
  },
};
</script>
