<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn
          text
          x-small
          :disabled="evalInvoiceReceived || dollarAmount == null"
          v-on="on"
          v-on:click="generateEvalInvoice"
          ><v-icon small>mdi-file-document-outline</v-icon></v-btn
        >
      </span>
    </template>
    <span> {{ title }} </span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["session", "evalInvoiceReceived", "dollarAmount"],
  computed: {
    title: function () {
      return this.dollarAmount == null && !this.evalInvoiceReceived
        ? "Invoice generation is disabled by payroll department"
        : "Generate Invoice";
    },
  },
  methods: {
    generateEvalInvoice: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      var message = await this.$store.dispatch(
        "evalInvoiceModule/generateEvalInvoices",
        [this.session]
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.$store.commit(
        "uxModule/setSnackbarMsg",
        message
          ? message
          : "Oops! Something went wrong. Please try again later."
      );
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
  },
};
</script>
