import * as api from "../Services/api.js";

export const state = {
  providerId: 0,
};

export const mutations = {
  SET_PROVIDER_ID(state, payload) {
    state.providerId = payload;
  },
};

export const getters = {};

export const actions = {
  async sendFeedbackEmail({ commit }, payload) {
    commit("uxModule/setShowLoader", true, { root: true });
    commit("uxModule/setShowSnackbar", false, { root: true });
    api.sendFeedbackEmail(payload.feedbackParams).then((response) => {
      commit("uxModule/setShowLoader", false, { root: true });
      if (response.data.success) {
        var msg = response.data.message;
        commit("uxModule/setSnackbarMsg", msg, {
          root: true,
        });
      } else {
        var msg = "Your feedback email was not sent. " + response.data.message;
        commit("uxModule/setSnackbarMsg", msg, {
          root: true,
        });
      }
      commit("uxModule/setShowSnackbar", true, { root: true });
    });
  },
  async getProviderId({ state, commit, rootState }) {
    let legacySystemId = rootState.securityModule.userProfile.legacySystemId;
    let email = rootState.securityModule.userProfile.email;

    return await api
      .getQuery("/provider/getProviderId", {
        legacyProviderId: legacySystemId,
        email: email,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_PROVIDER_ID", response.data);
        }

        return state.providerId;
      })
      .catch((error) => {
        return false;
      });
  },
  async getFileUrl({}, data) {
    let fileId = data.fileId;

    return await api
      .get("/files/" + fileId + "/url")
      .then((response) => {
        if (response.success) {
          return response.data;
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
