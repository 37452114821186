import axios from "axios";
const omniApiUrlPrefix = process.env.VUE_APP_OMNI_BASE_URL + "/api";
const securityApiUrlPrefix = process.env.VUE_APP_OMNI_SECURITY_BASE_URL;

export async function sendFeedbackEmail(data) {
  return axios
    .post(omniApiUrlPrefix + "/api/shared/sendFeedbackEmail", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getUser(data) {
  return axios
    .get(securityApiUrlPrefix + "/api/identity/getUser", {
      params: {
        userId: data,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export async function get(subURL) {
  return axios
    .get(omniApiUrlPrefix + subURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getQuery(subURL, data) {
  return axios
    .get(omniApiUrlPrefix + subURL, {
      params: data,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function post(subURL, data) {
  return axios
    .post(omniApiUrlPrefix + subURL, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function postQuery(subURL, data) {
  return axios
    .post(omniApiUrlPrefix + subURL, null, {
      params: data,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
