<template>
  <div>
    <v-card-subtitle v-if="noSessions"
      >You have no evaluation sessions to invoice.</v-card-subtitle
    >
    <v-sheet class="sticky" v-else>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-card-subtitle
        >To generate an invoice for a single session, click the
        <v-icon small>mdi-file-document-outline</v-icon> button on that session
        row.<br />To generate invoices for multiple sessions, select the rows of
        interest and then press the 'Bulk Invoice' button.
      </v-card-subtitle>
      <v-btn
        small
        color="primary"
        @click="generateEvalInvoices"
        :disabled="selected.length == 0"
      >
        <v-icon small>mdi-plus</v-icon>Bulk Invoice</v-btn
      >
      <v-spacer class="py-1"></v-spacer>
      <v-toolbar flat color="white">
        <v-subheader class="pb-4">Filter By</v-subheader>
        <v-col cols="6" sm="2" v-if="isAdmin">
          <v-autocomplete
            v-model="providerFilter"
            :items="providers"
            clearable
            label="Providers"
            dense
            class="mx-1"
            prepend-inner-icon="mdi-account-tie"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" sm="2">
          <v-autocomplete
            v-model="clientFilter"
            :items="clients"
            clearable
            label="Client"
            dense
            class="mx-1"
            prepend-inner-icon="mdi-account"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" sm="2">
          <form-field
            :field="datePicker"
            @fieldChange="dateOfServiceFilter = $event"
            class="mx-1"
          ></form-field>
        </v-col>
        <v-col cols="6" sm="3" class="px-2 pb-6">
          <v-switch
            v-model="missingInvoicesToggle"
            x-small
            hide-details
            class="mx-0"
          >
            <template v-slot:label>
              <span>Missing Invoices Only</span></template
            >
          </v-switch>
        </v-col>
        <v-btn class="ml-n16 pb-3" x-small text @click="clearFilters"
          >Clear Filters</v-btn
        >
      </v-toolbar>
      <v-spacer class="py-1"></v-spacer>
      <v-data-table
        :headers="computedHeaders"
        :items="filteredSessions"
        sort-by="dateOfService"
        sort-desc
        v-model="selected"
        item-key="sessionId"
        :loading="loading"
        loading-text="Loading... Please wait"
        show-select
        fixed-header
        dense
      >
        <template v-slot:[`header.data-table-select`]></template>
        <template
          v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
        >
          <v-simple-checkbox
            :value="isSelected"
            @input="select($event)"
            :disabled="item.evalInvoiceReceived || item.dollarAmount == null"
          ></v-simple-checkbox>
        </template>
        <template v-slot:[`item.evalInvoiceReceived`]="{ item }">
          <v-simple-checkbox :value="item.evalInvoiceReceived" disabled>
          </v-simple-checkbox>
        </template>
        <template v-slot:[`item.dollarAmount`]="{ item }">
          <v-tooltip bottom v-if="item.dollarAmount == null">
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-btn
                  text
                  x-small
                  v-on="on"
                  @click="sendMissingRateNotification(item.sessionId)"
                  ><v-icon>mdi-email-send-outline</v-icon></v-btn
                >
              </span>
            </template>
            <span> Notify payroll department of missing rate</span>
          </v-tooltip>
          <span v-else>{{ formatCurrency(item.dollarAmount) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <generate-invoice-action
              :session="item"
              :evalInvoiceReceived="item.evalInvoiceReceived"
              :dollarAmount="item.dollarAmount"
            >
            </generate-invoice-action>
            <preview-file-action
              :fileId="item.evalInvoiceFileId"
            ></preview-file-action>
            <download-file-action
              :fileId="item.evalInvoiceFileId"
              :filename="item.filename"
            ></download-file-action>
          </v-row>
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>

<script>
import DownloadFileAction from "../Shared/download-file-action.vue";
import PreviewFileAction from "../Shared/preview-file-action.vue";
import GenerateInvoiceAction from "./generate-invoice-action";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import FormField from "../../objs/Shared/FormField";
import { formatCurrency } from "../../utils/format-utils";
export default {
  props: ["sessions", "loading"],
  components: {
    PreviewFileAction,
    DownloadFileAction,
    GenerateInvoiceAction,
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    computedHeaders() {
      const headers = [
        {
          text: "Session ID",
          value: "sessionId",
          align: "left",
          sortable: false,
        },
        { text: "Client", value: "clientName" },
        { text: "Date of Service", value: "formattedDateOfService" },
        { text: "Program", value: "programName" },
        { text: "Service", value: "serviceName" },
        { text: "Location", value: "location", sortable: false },
        { text: "Group Size", value: "groupSize", sortable: false },
        { text: "Language", value: "language", sortable: false },
        { text: "Rate", value: "dollarAmount", sortable: false },
        {
          text: "Invoiced",
          value: "evalInvoiceReceived",
          align: "center",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ];
      if (this.isAdmin) {
        headers.splice(1, 0, { text: "Provider", value: "providerName" });
      }
      return headers;
    },
    noSessions() {
      return !this.loading && this.sessions.length == 0;
    },
    isAdmin() {
      return this.$store.getters["securityModule/hasUserRole"](["PayrollAdmin"]);
    },
    title() {
      return this.isAdmin ? "All Eval Sessions" : "My Eval Sessions";
    },
    clients() {
      return this.sessions
        .map((item) => {
          return {
            text: `${item.clientName}`,
            value: `${item.clientName}`,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    providers() {
      return this.sessions
        .map((item) => {
          return {
            text: `${item.providerName}`,
            value: `${item.providerName}`,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    datePicker() {
      return new FormField({
        label: "Date of Service",
        value: this.dateOfServiceFilter,
        componentName: FieldTypes.DATE_PICKER,
      });
    },
    clientFilter: {
      get() {
        return this.$store.state.evalInvoiceModule.filters.clientFilter || "";
      },
      set(value) {
        this.$store.dispatch("evalInvoiceModule/setFilters", {
          filterName: "clientFilter",
          filterValue: value,
        });
      },
    },
    providerFilter: {
      get() {
        return this.$store.state.evalInvoiceModule.filters.providerFilter || "";
      },
      set(value) {
        this.$store.dispatch("evalInvoiceModule/setFilters", {
          filterName: "providerFilter",
          filterValue: value,
        });
      },
    },
    dateOfServiceFilter: {
      get() {
        return (
          this.$store.state.evalInvoiceModule.filters.dateOfServiceFilter || []
        );
      },
      set(value) {
        this.$store.dispatch("evalInvoiceModule/setFilters", {
          filterName: "dateOfServiceFilter",
          filterValue: value,
        });
      },
    },
    missingInvoicesToggle: {
      get() {
        return (
          this.$store.state.evalInvoiceModule.filters.missingInvoicesToggle ||
          false
        );
      },
      set(value) {
        this.$store.dispatch("evalInvoiceModule/setFilters", {
          filterName: "missingInvoicesToggle",
          filterValue: value,
        });
      },
    },
    filteredSessions() {
      let sessions = this.sessions;
      if (this.providerFilter) {
        sessions = sessions.filter(
          (t) => t.providerName == this.providerFilter
        );
      }
      if (this.clientFilter) {
        sessions = sessions.filter((t) => t.clientName == this.clientFilter);
      }
      if (this.dateOfServiceFilter.length != 0) {
        sessions = sessions.filter(
          (t) =>
            new Date(t.dateOfService).toDateString() ==
            new Date(this.dateOfServiceFilter).toDateString()
        );
      }
      if (this.missingInvoicesToggle === true) {
        sessions = sessions.filter((t) => !t.evalInvoiceReceived);
      }
      return sessions;
    },
  },
  methods: {
    formatCurrency,
    clearFilters() {
      this.$store.dispatch("evalInvoiceModule/clearFilters");
    },
    generateEvalInvoices: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      var message = await this.$store.dispatch(
        "evalInvoiceModule/generateEvalInvoices",
        this.selected
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.selected = [];
      this.$store.commit(
        "uxModule/setSnackbarMsg",
        message
          ? message
          : "Oops! Something went wrong. Please try again later."
      );
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
    sendMissingRateNotification: async function (sessionId) {
      this.$store.commit("uxModule/setShowLoader", true);
      var success = await this.$store.dispatch(
        "evalInvoiceModule/sendMissingRateNotification",
        sessionId
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.$store.commit(
        "uxModule/setSnackbarMsg",
        success
          ? "Notification sent. You will be notified when the session is ready for invoice."
          : "Oops! Something went wrong. Please try again later."
      );
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
  },
};
</script>
