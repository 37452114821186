<template>
  <v-data-table
    :headers="headers"
    :items="requests"
    sort-by="requestSubmittedAt"
    sort-desc
  >
    <template v-slot:[`item.actions`]="{ item }">
      <view-request-action v-if="!item.pending" :request="item"></view-request-action>
      <edit-request-action v-if="item.pending" :request="item">
      </edit-request-action>
      <delete-request-modal
        v-if="item.pending"
        :requestId="item.paidSickLeaveRequestId"
      ></delete-request-modal>
    </template>
  </v-data-table>
</template>
<script>
import ViewRequestAction from "./view-request-action.vue";
import EditRequestAction from "./edit-request-action.vue";
import DeleteRequestModal from "./delete-request-modal.vue";
export default {
  components: {
    ViewRequestAction,
    EditRequestAction,
    DeleteRequestModal,
  },
  data() {
    return {
      headers: [
        { text: "Request ID", value: "paidSickLeaveRequestId", align: "left" },
        { text: "Start Date", value: "formattedStartDate" },
        { text: "End Date", value: "formattedEndDate" },
        { text: "Total Hours", value: "hoursRequested" },
        { text: "Reason", value: "reason" },
        { text: "Date Submitted", value: "formattedSubmittedDate" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    requests() {
      return this.$store.state.paidSickLeaveModule.paidSickLeaveRequests || [];
    },
  },
};
</script>