import axios from "axios";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "file-saver";

export const downloadFile = async (fileUrl, fileName) => {
  axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

export const downloadZip = async (files, store, zipFileName) => {
  var zip = new JSZip();
  var count = 0;

  files.forEach(async (file) => {
    const fileUrl = await store.dispatch("sharedModule/getFileUrl", {
      fileId: file.fileId,
    });

    try {
      const data = await JSZipUtils.getBinaryContent(fileUrl);
      zip.file(file.filename, data, { binary: true });
      count++;
      if (count === files.length) {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, zipFileName);
        });
      }
    } catch (err) {
      throw err;
    }
  });
};
